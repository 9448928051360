import './App.css';
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {addCustomerAction, removeCustomerAction} from "./store/customerReducer";
import {fetchCustomers} from "./asyncAction/customers";

function App() {

    const dispatch = useDispatch();
    const cash = useSelector(state => state.cash.cash);
    const customers = useSelector(state => state.customers.customers);

    const addCash = (cash) => {
        dispatch({type:"ADD_CASH", payload: cash})
    };
    const getCash = (cash) => {
        dispatch({type:"GET_CASH", payload: cash})
    };
    const addCustomer = (name) => {
        const customer = {
            name,
            id: Date.now(),

        };
        dispatch(addCustomerAction(customer))
    };
    const removeCustomer = (customer) => {
        dispatch(removeCustomerAction(customer.id))
    };

    return (
        <div className="App">
            <div className="cash">{cash}</div>
            <div className="bb">
                <button onClick={() => addCash(Number(prompt()))}>Пополниь счёт</button>
                <button onClick={() => getCash(Number(prompt()))}>Снять со счёта</button>
            </div>
            <div className="bb" style={{marginTop:"20px"}}>
                <button onClick={() => addCustomer(prompt())}>Добавить клиента</button>
                <button onClick={() => dispatch(fetchCustomers())}>Получить клиентов из базы</button>
            </div>
            {customers.length > 0 ?
                <div>
                    {customers.map(customer =>
                        <div className="cust" key={customer.id} onClick={() => removeCustomer(customer)}>
                            {customer.name}
                        </div>
                    )}

                </div>
                :
                <div className="cust">
                    Клиенты отсутствуют !
                </div>
            }
        </div>
    );
}

export default App;
